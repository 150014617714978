.ekskursii{
	margin-right:15px;
	margin-left:15px;
	margin-top:100px;
}

.exc__subtitle {
    font-size: 26px;
    font-weight: 300;
    text-align: center;
    max-width: 600px;
    margin: auto;
    margin-bottom: 80px;
}


.exc__item-wrap{
	margin-bottom: 80px;
}

.exc__item{
	// margin-left:40px;
	position:relative;
	margin-right:10px;
	margin-left: 10px;
	overflow:hidden;
	border-bottom:1px solid #EAEAEB;
	transition:all 0.3s ease-out;
	& a{
		border: 1px solid transparent; //- хак для андройд. Иначе просвечивает фото под названием
	}
}

.exc__img-wrap{
	display: flex;
    align-items: center;
	overflow:hidden;
	margin-bottom:60px;
	height:300px;
	& img{
		display: block;
		transition:all 1s ease-out;
	}
}

.exc__item:hover {
	& .exc__title{
		color: #f6c30e;
		transition:all 0.3s ease-out;
	}
	& .exc__button{ 
		opacity:1;
		transition:all 0.3s ease-out;
	}
	& .exc__img-wrap img{
		transform:scale(1.1);
		transition:all 1s ease-out;
	}
}

.exc__text{
	position:absolute;
	left:0;
	right:0;
	bottom:0;
}

.exc__title {
	max-width: 75%;
	background:#fff;
	padding:20px 40px 5px 0; //- оставляем нижний паддинг, чтобы не просвечивало фото
	display: inline-block;
	font-family: $fontheader;
	font-size: 18px;
    font-weight: 700;
	color: $headercolor;
	line-height:1.3;
	padding-bottom:4px;
	transition:all 0.3s ease-out;
}

.exc__details{
	height:60px;
	display:flex;
	justify-content: space-between;
	align-items:center;
}

.exc__duration{
	position: relative;
	padding-left:34px;
	color: $brightblue;
	font-size:16px;
}

.exc__duration:before{
	content:"";
	position: absolute;
	display: block;
	top:0;
	left:0;
	height:30px;
	width:30px;
	background:url("../img/icons/icon-time-span.png") center center no-repeat;
	background-size:contain;
}

.exc__button{
    display: flex;
    align-items: center;
    opacity:0.6;
    transition:all 0.3s ease-out;
}

.exc__row{
	display:flex;
	justify-content: space-between;
	align-items:center;
}


@media screen and (min-width: 576px){
	.exc__item-wrap{
		flex-basis:50%;	
	}
}

@media screen and (min-width: 768px){

}

@media screen and (min-width: 992px){
	.exc__img-wrap{
		height:100%;
	}
	.exc__item-wrap{
		flex-basis:33%;	
	}
}

@media screen and (min-width: 1200px){
	.ekskursii{
		margin-right:40px;
		margin-left:40px;
		margin-top:0;
	}
	.exc__item{
		margin-right:20px;
		margin-left: 20px;
		// flex-basis:500px;	
	}
	.exc__title{
		font-size:22px;
		letter-spacing: 0;
	}
	.exc__details{
		height:90px;
	}
	.exc__duration{
		padding-left:44px;
		font-size:20px;
	}
	.exc__duration:before{
		height:40px;
		width:40px;
	}
	.exc__img-wrap{
		margin-bottom:90px;
	}
}
